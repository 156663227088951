import { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Container, Typography, Switch,Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MoreMenu, DataGridRender } from 'src/components/table';
import MessageContext from 'src/context/MessageContext';
import { BidAllRequest, BidDeleteRequest, BidDownloadRequest, BidStatusUpdateRequest } from 'src/actions/bidActions';
import TopBar from 'src/components/shared/TopBar';

export default function BidList() {

  const { setMessageHandler } = useContext<any>(MessageContext);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status] = useState(false);
  const [filters, setFilters] = useState<any>({});
  const [search, setSearch] = useState<string>('');

  const dispatch = useDispatch();
  const { loading, metadata, data } = useSelector(state => state.bid);
  

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(BidAllRequest(filters, search, pageNo, limit, status));
  }, [pageNo, search, limit, status, filters]);

const downloadBidAction =(id:string,name: string)=>{
  dispatch(BidDownloadRequest({ bidId: id },name));
}

  const deleteAction = (id: string) => {
    dispatch(BidDeleteRequest(id, { isDeleted: true }, setMessageHandler));
  }
  const editAction = (id: string) => {
    navigate(`/admin/bid/${id}/edit`);
  }

  const columns = [
    {
      field: 'bidTitle', type: "string", headerName: 'Bid Name', filterable: false, headerAlign: "left", align: 'left', width: 200, renderCell: ({ id, value }: any) => {
        return <Tooltip title={value}>
          <RouterLink to={`/admin/bid/${id}/edit`}> <Typography onClick={() => editAction(id)} color="primary" >{value}</Typography></RouterLink>
        </Tooltip>
      }
    },
    { field: 'bidReferenceNumber', type: "string", headerName: 'Reference Number', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'clientName', type: "string", headerName: 'Client Name', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    { field: 'createdAt', type: "string", headerName: 'Created Date', filterable: false, flex: 1, headerAlign: "center", align: 'center' },
    {
      field: 'isActive', headerName: 'Status', type: "number", width: 80, valueFormatter: ({ value }: any) => value ? "Active" : "InActive",
      renderCell: (params: any) => {
        return <Switch checked={params.value} onChange={() => dispatch(BidStatusUpdateRequest(params.id, { isActive: !params.value }, setMessageHandler))} />
      }
    },
    { field: 'actions', headerName: '', width: 40, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['bidTitle'] }  cloneAction="" addAction='' downloadBidAction={downloadBidAction} editAction={editAction} deleteAction={deleteAction} exportAction="" />] },
  ];


  return (
    <Container maxWidth={false}>
      <TopBar title="Bids" link="/admin/bid/add" buttonText="New Bid" />
      <DataGridRender
        width={1}
        loading={loading}
        height={620}
        filterTypes={['clients', 'status', 'export', 'search']}
        exportFileName={"Bids"}
        filters={filters}
        data={data}
        metadata={metadata}
        limit={limit}
        search={search}
        pageNo={pageNo}
        columns={columns}
        setPageNo={setPageNo}
        setSearch={setSearch}
        setLimit={setLimit}
        setFilters={setFilters}
      />
    </Container>
  );
}
