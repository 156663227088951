import { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Container, Typography, Box, Tooltip, Button, Tab } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/reducers';
import { MoreMenu, DataGridRender } from 'src/components/table';
import TopBar from 'src/components/shared/TopBar';
import plusFill from '@iconify/icons-eva/plus-fill';
import CSVReader from 'react-csv-reader'
import { Icon } from '@iconify/react';
import ResponsiveDialog from 'src/components/dialog/ResponsiveDialog';
import { InvoiceSupplierDownloadRequest, InvoiceSupplierAllRequest, InvoiceUploadRequest, InvoiceSupplierUploadRequest } from 'src/actions/invoiceActions';
import moment from 'moment';
import { InvoiceStatus } from 'src/constants';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import { MessageContext } from 'src/context';
import TabLayout from 'src/components/tab/TabLayout';

const papaparseOptions: any = {
	header: false,
	dynamicTyping: true,
	skipEmptyLines: true,
	transformHeader: (header: any) =>
		header
			.toLowerCase()
			.replace(/\W/g, '_')
}

export default function PurchaseOrderSupplierList() {

	const { setMessageHandler } = useContext<any>(MessageContext);
	const [pageNo, setPageNo] = useState<number>(1);
	const [limit, setLimit] = useState<number>(20);
	const [dialog, setDialog] = useState<boolean>(false);
	const [filters, setFilters] = useState<any>({});
	const [invoiceData, setInvoiceData] = useState<any>([]);

	const dispatch = useDispatch();
	const { loading, redirectTo, metadata, datasupplier } = useSelector(state => state.invoice);

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(InvoiceSupplierAllRequest(filters, pageNo, limit))
	}, [pageNo, limit, filters, redirectTo]);


	useEffect(() => {
		if (redirectTo) { setDialog(false); setInvoiceData([]); }
	}, [redirectTo]);

	const editAction = (id: string) => {
		navigate(`/admin/invoice/generate/${id}/edit`, { replace: true });
	}

	const editStatusAction = (id: string) => {
		navigate(`/admin/invoice/status/${id}/edit`, { replace: true });
	}

	const reloadAction = () => {
		dispatch(InvoiceSupplierAllRequest(filters, pageNo, limit));
	}

	const downloadInvoiceAction = (id: string) => {
		dispatch(InvoiceSupplierDownloadRequest( id));
	}

	const viewInvoiceAction = (id: string) => {
		navigate(`/admin/invoice/view/${id}`);
	}

	const invoiceUploadAction = () => {
		return <>
			<ResponsiveDialog openDialog={dialog} subTitle="" setOpenDialog={setDialog} title="Upload Ref. No." >
				<Box sx={{ mb: 2 }}>
					<Link to="/assets/csv/invoicetest.csv" target="_blank" download>Download Sample</Link>
				</Box>
				<CSVReader parserOptions={papaparseOptions} onFileLoaded={(data): any => {
					setInvoiceData(data);
				}} />
				<Button
					sx={{ mt: 4, height: 40 }}
					variant="contained"
					disabled={!invoiceData.length ? true : false}
					onClick={() => dispatch(InvoiceSupplierUploadRequest({ data: invoiceData }, setMessageHandler))}
					startIcon={<Icon icon={plusFill} />}
				>
					Upload
				</Button>
			</ResponsiveDialog>
		</>
	}


	const columns = [
		{
			field: 'invoiceCode', type: "string", headerName: 'PO #', filterable: false, headerAlign: "center", align: 'center', width: 150, pinnable: true, renderCell: ({ value }: any) => {
				return <Typography color="primary" >{value}</Typography>
			}
		},
		{
			field: 'supplierName', type: "string", headerName: 'Supplier Name', filterable: false, headerAlign: "left", align: 'left', flex: 1, renderCell: ({ value }: any) => {
				return <Tooltip title={value}>
					<Typography variant="subtitle2">{value}</Typography>
				</Tooltip>
			}
		},
		{
			field: 'createdAt', type: "string", headerName: 'Created Date', filterable: false, width: 120, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
				return moment(row['createdAt']).utcOffset("+05:30").format('DD/MM/YYYY');
			},
		},
		{
			field: 'dueDate', type: "string", headerName: 'Due Date', filterable: false, width: 120, headerAlign: "center", align: 'center', renderCell: ({ row }: any) => {
				return moment(row['dueDate']).utcOffset("+05:30").format('DD/MM/YYYY');
			},
		},
		{
			field: 'recievedDate', type: "string", headerName: 'Recieved Date', filterable: false, width: 120, headerAlign: "center", align: 'center'
		},
		{
			field: 'referenceNo', type: "string", headerName: 'Reference', filterable: false, width: 120, headerAlign: "center", align: 'center'
		},
		{
			field: 'comment', type: "string", headerName: 'Comment', filterable: false, width: 220, headerAlign: "center", align: 'center'
		},
		{
			field: 'total', type: "string", headerName: 'Amount', filterable: false, width: 120, headerAlign: "center", align: 'center', valueGetter: ({ row }: any) => {
				return `$${row['total'].toFixed(2)}`;
			},
		},
		{
			field: 'invoiceStatus', headerName: 'Status', type: "string", width: 120, headerAlign: "center", align: 'center', valueFormatter: ({ value }: any) => InvoiceStatus.find(status => status.value == value)?.label,
			renderCell: (params: any) => {
				var status: any = InvoiceStatus.find(status => status.value == params.value);
				return <Label
					variant="ghost"
					color={status?.color}
				>
					{sentenceCase(status.label)}
				</Label>
			}
		},
		{ field: 'actions', headerName: '', width: 60, type: 'actions', getActions: ({ id, row }: any) => [<MoreMenu id={id} name={row['projectCode'] + "_" + row['projectName']} projectStatus={row['projectStatus']} exportFileName="Project" reconsileAction="" cloneAction='' addAction='' editAction="" editStatusAction="" deleteAction="" exportSupplierMap="" exportAction="" viewInvoiceAction="" downloadInvoiceAction={downloadInvoiceAction} />] },
	];


	return (
		<Container maxWidth={false}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box>
					<Typography sx={{ ml: 1 }} variant="h5">
						Accounts
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<TopBar title="" link="/admin/invoice/generate" buttonText="Generate Invoice" />
					<Box sx={{ height: 30, ml: 1, mt: 1 }}>
						<Button
							variant="contained"
							size="medium"
							startIcon={<Icon icon={plusFill} />}
							onClick={() => setDialog(true)}
						>
							Upload Ref. No.
						</Button>
					</Box>
				</Box>
			</Box>
			<TabLayout handleChange="" tabValue={5}>
				<Tab label="Ready To Invoice" href="/admin/invoice" value={1} />
				<Tab label="Archieved" href="/admin/invoice/archieved" value={3} />
				<Tab label="Invoices" href="/admin/invoice/list" value={2} />
				<Tab label="Finances" href="/admin/invoice/supplier" value={4} />
				<Tab label="Purchase Order" href="/admin/invoice/supplier/list" value={5} />
			</TabLayout>
			{invoiceUploadAction()}
			<DataGridRender
				reloadAction={reloadAction}
				getRowClassName={(params: any) => params.row['complete'] && params.row['complete'] >= params.row['sampleSize'] && params.row['sampleSize'] ? `data-grid-warning` : 'super-app-theme'}
				loading={loading}
				filterTypes={['client', 'user', 'export', 'search']}
				data={datasupplier}
				filters={filters}
				exportFileName="Invoices"
				metadata={metadata}
				limit={limit}
				pageNo={pageNo}
				columns={columns}
				setPageNo={setPageNo}
				setLimit={setLimit}
				setFilters={setFilters}
			/>
		</Container>
	);
}
