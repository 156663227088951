import { useContext } from 'react';
import {  Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ClientUpdateRequest, ClientSingleRequest } from 'src/actions';
import { ClientForm } from 'src/components/admin/_dashboard/client';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'src/reducers';
import { Client } from 'src/types/clientTypes';
import MessageContext from 'src/context/MessageContext';
import TopBarForm from 'src/components/shared/TopBarForm';

export default function EditBid() {
    const { setMessageHandler } = useContext<any>(MessageContext);
    const dispatch = useDispatch();
    const { clientid }: any = useParams<any>();
    const submitForm = async (values: Client, setSubmitting: any) => {
        setTimeout(() => dispatch(ClientUpdateRequest(clientid, values, setMessageHandler, setSubmitting)), 200);
    }
    const { client } = useSelector(state => state.client);
    var initialValues: Client = client

    useEffect(() => {
        dispatch(ClientSingleRequest(clientid));
    }, [clientid])

    return (
        <Container maxWidth={false}>
            <TopBarForm title="Edit Client" link="/admin/client"  />
            <ClientForm submitForm={submitForm} initialValues={initialValues} />
        </Container>
    );
}
