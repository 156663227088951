import { useEffect } from 'react';
import * as Yup from 'yup'
import { styled } from '@mui/material/styles';
import { Card, Stack, TextField, Grid } from '@mui/material';
import {  Form, Formik, FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'src/reducers';
import { useNavigate } from 'react-router-dom';
import {  Bid } from 'src/types/bidTypes';
import { useDispatch } from 'react-redux';
import { ClientAllRequestData } from 'src/actions/clientActions';

const CardStyle = styled(Card)(({ }) => ({
    width: '100%',
    padding: 24
}));

export interface SubmitFormValues {
    initialValues: Bid,
    submitForm: any,
}

export default function BidForm({ initialValues, submitForm }: SubmitFormValues) {

    const dispatch = useDispatch();
    const SchemaBidForm = Yup.object().shape({
        bidTitle: Yup.string().min(4).trim().required('Bid title  is required'),
        bidContact: Yup.string().trim().required('Bid contact  is required'),
        clientId: Yup.string().trim().required('Client is required')
    });
    const navigate = useNavigate();

    const { redirectTo } = useSelector(state => state.bid);
    const { clients } = useSelector(state => state.client);

    useEffect(() => {
        if (redirectTo) navigate(redirectTo, { replace: true })
    }, [redirectTo])


    useEffect(() => {
        dispatch(ClientAllRequestData(''))
    }, [])



    return (
        <CardStyle>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    submitForm(values, setSubmitting);
                }}
                validateOnBlur={false}
                validateOnChange={true}
                enableReinitialize={true}
                validationSchema={SchemaBidForm}
                initialValues={{
                    bidTitle: initialValues?.bidTitle || '',
                    bidContact: initialValues?.bidTitle || '',
                    clientId: initialValues?.clientId || '',
                }}
            >
                {(formikProps: FormikProps<Bid>) => (
                    <Form autoComplete="off" noValidate onSubmit={formikProps.handleSubmit}>
                        <Stack spacing={3}>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.bidTitle && formikProps.errors.bidTitle)}
                                        fullWidth
                                        helperText={formikProps.touched.bidTitle && formikProps.errors.bidTitle}
                                        label="Bid Title"
                                        variant="standard"
                                        {...formikProps.getFieldProps('bidTitle')}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(formikProps.touched.bidContact && formikProps.errors.bidContact)}
                                        fullWidth
                                        helperText={formikProps.touched.bidContact && formikProps.errors.bidContact}
                                        label="Bid Contact"
                                        variant="standard"
                                        {...formikProps.getFieldProps('bidContact')}
                                    />
                                </Grid>
                                
                                <Grid
                                    item
                                    md={4}
                                    xs={12}
                                >
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        label="Country Name"
                                        error={Boolean(formikProps.touched.clientId && formikProps.errors.clientId)}
                                        fullWidth
                                        {...formikProps.getFieldProps('clientId')}
                                        helperText={formikProps.touched.clientId && formikProps.errors.clientId}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    >
                                        <option key="" value="">
                                            {"Select Client"}
                                        </option>
                                        {clients.map((client: any) => (
                                            <option key={client._id} value={client._id}>
                                                {client.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={formikProps.isSubmitting}
                                >
                                    {initialValues ? 'Save Bid' : 'Create Bid'}
                                </LoadingButton>
                            </Grid>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </CardStyle>
    );
}
